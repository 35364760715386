import { gql } from '@apollo/client'

export default gql`
  mutation customerUpdatePhone($phoneToken: String!, $id: Int!) {
    customerUpdatePhone(phoneToken: $phoneToken, id: $id) {
      customer {
        id
        authenticationToken
        email
        completeProfile
        anonymous
        phone
      }
      errors {
        fullMessage
        key
        message
      }
    }
  }
`
