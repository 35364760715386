/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../../global-graphql-types'

import { DocumentNode } from 'graphql'
import { CurrentUserFragment } from '../../../queries/types/CurrentUserFragment'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from 'utils/extendApolloHooks'
const defaultOptions = {} as const
export type CustomerUpdateEmailVariables = Types.Exact<{
  id: Types.Scalars['Int']
  email: Types.Scalars['String']
}>

export type CustomerUpdateEmail = {
  __typename: 'Root'
  customerUpdateEmail: {
    __typename: 'CustomerUpdateEmailPayload'
    errors: Array<{
      __typename: 'UserError'
      fullMessage: string | null
      key: string | null
      message: string | null
    }> | null
    customer: {
      __typename: 'Customer'
      id: number
      name: string | null
      firstName: string | null
      lastName: string | null
      email: string
      phone: any | null
      completeProfile: boolean
      anonymous: boolean
      authenticationToken: string
      referralCode: string
      tawkHash: string | null
      amountCredit: { __typename: 'Money'; cents: number; formatted: string }
    } | null
  } | null
}

export const CustomerUpdateEmailDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomerUpdateEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerUpdateEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullMessage' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CurrentUserFragment' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...CurrentUserFragment.definitions
  ]
} as unknown) as DocumentNode
export type CustomerUpdateEmailMutationFn = Apollo.MutationFunction<
  CustomerUpdateEmail,
  CustomerUpdateEmailVariables
>

/**
 * __useCustomerUpdateEmail__
 *
 * To run a mutation, you first call `useCustomerUpdateEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerUpdateEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerUpdateEmail, { data, loading, error }] = useCustomerUpdateEmail({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCustomerUpdateEmail(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CustomerUpdateEmail,
    CustomerUpdateEmailVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CustomerUpdateEmail,
    CustomerUpdateEmailVariables
  >(CustomerUpdateEmailDocument, options)
}
export type CustomerUpdateEmailHookResult = ReturnType<
  typeof useCustomerUpdateEmail
>
export type CustomerUpdateEmailMutationResult = Apollo.MutationResult<CustomerUpdateEmail>
export type CustomerUpdateEmailMutationOptions = Apollo.BaseMutationOptions<
  CustomerUpdateEmail,
  CustomerUpdateEmailVariables
>
