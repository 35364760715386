import React, { useEffect, useState } from 'react'
import VerifyPhone from 'components/pages/ProfilePage/PhoneForm/VerifyPhone'
import ConfirmationCodeForm from 'components/pages/ProfilePage/PhoneForm/ConfirmationCodeForm'
import Title from 'components/ui/Title'
import { useMutation } from '@apollo/client'
import { customerUpdatePhone } from 'components/pages/ProfilePage/EditPhoneForm/types/customerUpdatePhone'
import CUSTOMER_UPDATE_PHONE from 'components/pages/ProfilePage/EditPhoneForm/customerUpdatePhone'
import useUser from 'queries/useUser'
import { CurrentUserFragment } from 'queries/types/CurrentUserFragment'
import CompleteNameForm from 'components/CompleteProfileForm/CompleteNameForm'
import getMutationError from 'utils/getMutationError'

type Props = {
  onComplete: (customer: CurrentUserFragment) => void
}

type Step = null | 'name' | 'phone' | 'complete'

function CompleteProfileForm({ onComplete }: Props) {
  const { user } = useUser({ skip: typeof window === 'undefined' })
  const [verifying, setVerifying] = useState(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [step, setStep] = useState<Step>(null)

  useEffect(() => {
    if (user!.anonymous) {
      setFormError(null)
      setStep('name')
      return
    }

    if (!user!.phone) {
      setFormError(null)
      setStep('phone')
      return
    }

    onComplete(user!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [phone, setPhone] = useState(user?.phone)
  const [customerUpdatePhoneMutation] = useMutation<customerUpdatePhone>(
    CUSTOMER_UPDATE_PHONE
  )

  const onVerificationSent = async (newPhone: string) => {
    setPhone(newPhone)
    setVerifying(true)
  }

  const onChangePhone = () => {
    setFormError(null)
    setVerifying(false)
  }

  const onCompleteName = () => {
    if (!user?.phone) {
      setStep('phone')
    }
  }

  const onPhoneToken = async (token: string) => {
    setVerifying(false)
    const response = await customerUpdatePhoneMutation({
      variables: {
        phoneToken: token,
        id: user!.id
      }
    })
    const error = getMutationError(response)
    if (error) {
      setFormError(error)
      return null
    }

    onComplete(user!)
    return null
  }

  return (
    <div className="bg-white md:mx-0 p-6 mx-4 rounded-xl">
      <Title variant="sectionTitle">Complete Contact Information</Title>
      {formError && <p className="text-red-500 mb-4 italic">{formError}</p>}
      {step === 'phone' && (
        <>
          <VerifyPhone
            buttonName="Verify Phone"
            catering={false}
            onChangePhone={onChangePhone}
            onVerificationSent={onVerificationSent}
            verifying={verifying}
            showTerms
          />
          {verifying && (
            <ConfirmationCodeForm onSuccess={onPhoneToken} phone={phone} />
          )}
        </>
      )}
      {step === 'name' && (
        <CompleteNameForm customer={user!} onComplete={onCompleteName} />
      )}
    </div>
  )
}

export default CompleteProfileForm
