import React from 'react'
import cn from 'classnames'

export type Props = {
  label?: string
  description?: string
  placeholder?: string
  error?: string | null
  rows?: number
  className?: string
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

export default function TextAreaField({
  label,
  description,
  placeholder,
  error,
  className,
  ...inputProps
}: Props) {
  return (
    <div className="w-full">
      {label && (
        <label
          className="block font-medium text-gray-700"
          htmlFor={inputProps.name}
        >
          {label}
        </label>
      )}
      <textarea
        className={cn(
          'border border-solid border-gray-300 p-2 outline-none-mouse focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm rounded-md scroll-offset',
          { 'ring-red-500 border-red-500': error, 'mt-1': label },
          className || ''
        )}
        placeholder={placeholder}
        {...inputProps}
      />
      {error && (
        <div>
          <p className="text-sm italic text-red-500 font-light mt-1">{error}</p>
        </div>
      )}
      {description && (
        <div>
          <p className="text-sm italic text-gray-500 font-light mt-1">
            {description}
          </p>
        </div>
      )}
    </div>
  )
}
