import FormTextField from 'components/ui/FormTextField'
import FieldSpacer from 'components/ui/FieldSpacer'
import FormError from 'components/ui/FormError'
import Button from 'components/ui/Button'
import { Form } from 'react-final-form'
import React, { useMemo } from 'react'
import { SubmitErrors } from 'components/pages/ProfilePage/EditProfileForm'
import getMutationErrors from 'utils/getMutationErrors'
import { CurrentUserFragment } from 'queries/types/CurrentUserFragment'

import { useCustomerUpdateName } from './types/CustomerUpdateName'
import { useCustomerUpdateEmail } from './types/CustomerUpdateEmail'

type SubmitValues = {
  firstName: string
  lastName: string
  email: string
}

const validate = (values: SubmitValues) => {
  const errors: SubmitErrors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (!values.email) {
    errors.lastName = 'Required'
  }
  return errors
}

type Props = {
  customer: CurrentUserFragment
  onComplete: () => void
}

export default function CompleteNameForm({ customer, onComplete }: Props) {
  const initialValues = useMemo(
    () => ({
      firstName: customer.anonymous ? '' : customer.firstName!,
      lastName: customer.anonymous ? '' : customer.lastName!,
      email: customer.email
    }),
    [customer.anonymous, customer.firstName, customer.lastName, customer.email]
  )

  const [customerUpdateName] = useCustomerUpdateName({})
  const [customerUpdateEmail] = useCustomerUpdateEmail({})

  const onSubmit = async (values: SubmitValues) => {
    const responseName = await customerUpdateName({
      variables: {
        id: customer.id,
        firstName: values.firstName,
        lastName: values.lastName
      }
    })

    const mutationErrorsName = getMutationErrors(responseName!, values)

    if (mutationErrorsName) return mutationErrorsName

    const responseEmail = await customerUpdateEmail({
      variables: {
        id: customer.id,
        email: values.email
      }
    })

    const mutationErrorsEmail = getMutationErrors(responseEmail!, values)

    if (mutationErrorsEmail) return mutationErrorsEmail

    onComplete()

    return null
  }

  return (
    <Form<SubmitValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormTextField label="First Name" name="firstName" />
          <FieldSpacer variant="tight" />
          <FormTextField label="Last Name" name="lastName" />
          <FieldSpacer variant="tight" />
          <FormTextField autoComplete="email" label="Email" name="email" />
          <FieldSpacer variant="tight" />
          <FormError />
          <div className="py-3 text-right">
            <Button
              color="primary"
              disabled={submitting}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}
