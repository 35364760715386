import Modal from 'react-modal'
import React from 'react'
import CompleteProfileForm from 'components/CompleteProfileForm'
import { CurrentUserFragment } from 'queries/types/CurrentUserFragment'

type Props = {
  open: boolean
  onClose: () => void
  onComplete: (customer: CurrentUserFragment) => void
}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: '8px',
    boxShadow: '1px 1px 4px rgb(174,174,174)'
  },
  overlay: {
    backgroundColor: 'rgba(174,174,174,0.75)'
  }
}

export default function CompleteProfileModal({
  open,
  onComplete,
  onClose
}: Props) {
  return (
    <Modal
      contentLabel="Complete Contact Information"
      isOpen={open}
      onRequestClose={onClose}
      style={modalStyles}
    >
      <CompleteProfileForm onComplete={onComplete} />
    </Modal>
  )
}
